import React from "react";

import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/SEO/seo";
import TaxiToEindhovenComponent from "../../../components/TaxiToEindhoven/index";

const TaxiToEindhoven = () => (
  <Layout>
    <SEO
      title="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
      meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
      description="With us, get fast, secure and cheap taxi Kiel to Neumunster or vice versa and enjoy an unforgettable experience ever embraced with unprecedented privileges."
    />
    <TaxiToEindhovenComponent language="DE" />
  </Layout>
);

export default TaxiToEindhoven;
